import React, { Component } from "react";

import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Tilt from "react-tilt";

class StoreAchievements extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid" style={{ backgroundColor: "#FBFBFD" }}>
          <div className="container">
            <div className="row">
              {localStorage.getItem("desktopAchievementOneTitle") !==
                "null" && (
                <Fade left>
                  <div className="col-xl-3 d-table mt-50 mb-50">
                    <Tilt
                      className="Tilt"
                      options={{ max: 40, perspective: 650 }}
                    >
                      <div className="col-xl-12 text-center d-table-cell align-middle store-achievement">
                        {localStorage.getItem("desktopAchievementOneTitle") !==
                          "null" && (
                          <h3>
                            {localStorage.getItem("desktopAchievementOneTitle")}
                          </h3>
                        )}
                        {localStorage.getItem("desktopAchievementOneSub") !==
                          "null" && (
                          <h4 className="m-0">
                            {localStorage.getItem("desktopAchievementOneSub")}
                          </h4>
                        )}
                      </div>
                    </Tilt>
                  </div>
                </Fade>
              )}
              {localStorage.getItem("desktopAchievementTwoTitle") !==
                "null" && (
                <Flip top>
                  <div className="col-xl-3 d-table mt-50 mb-50">
                    <Tilt
                      className="Tilt"
                      options={{ max: 40, perspective: 650 }}
                    >
                      <div className="col-xl-12 text-center d-table-cell align-middle store-achievement">
                        {localStorage.getItem("desktopAchievementTwoTitle") !==
                          "null" && (
                          <h3>
                            {localStorage.getItem("desktopAchievementTwoTitle")}
                          </h3>
                        )}
                        {localStorage.getItem("desktopAchievementTwoSub") !==
                          "null" && (
                          <h4 className="m-0">
                            {localStorage.getItem("desktopAchievementTwoSub")}
                          </h4>
                        )}
                      </div>
                    </Tilt>
                  </div>
                </Flip>
              )}
              {localStorage.getItem("desktopAchievementThreeTitle") !==
                "null" && (
                <Flip bottom>
                  <div className="col-xl-3 d-table mt-50 mb-50">
                    <Tilt
                      className="Tilt"
                      options={{ max: 40, perspective: 650 }}
                    >
                      <div className="col-xl-12 text-center d-table-cell align-middle store-achievement">
                        {localStorage.getItem(
                          "desktopAchievementThreeTitle"
                        ) !== "null" && (
                          <h3>
                            {localStorage.getItem(
                              "desktopAchievementThreeTitle"
                            )}
                          </h3>
                        )}
                        {localStorage.getItem("desktopAchievementThreeSub") !==
                          "null" && (
                          <h4 className="m-0">
                            {localStorage.getItem("desktopAchievementThreeSub")}
                          </h4>
                        )}
                      </div>
                    </Tilt>
                  </div>
                </Flip>
              )}
              {localStorage.getItem("desktopAchievementFourTitle") !==
                "null" && (
                <Fade right>
                  <div className="col-xl-3 d-table mt-50 mb-50">
                    <Tilt
                      className="Tilt"
                      options={{ max: 40, perspective: 650 }}
                    >
                      <div className="col-xl-12 text-center d-table-cell align-middle store-achievement">
                        {localStorage.getItem("desktopAchievementFourTitle") !==
                          "null" && (
                          <h3>
                            {localStorage.getItem(
                              "desktopAchievementFourTitle"
                            )}
                          </h3>
                        )}
                        {localStorage.getItem("desktopAchievementFourSub") !==
                          "null" && (
                          <h4 className="m-0">
                            {localStorage.getItem("desktopAchievementFourSub")}
                          </h4>
                        )}
                      </div>
                    </Tilt>
                  </div>
                </Fade>
              )}

              {localStorage.getItem("desktopBannerOne") !== "null" && (
                <Fade left>
                  <div className="col-xl-4 d-table mt-50 mb-50">
                    <img
                      src={localStorage.getItem("desktopBannerOne")}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                      }}
                      alt="desktopBannerOne"
                    />
                  </div>
                </Fade>
              )}
              {localStorage.getItem("desktopBannerTwo") !== "null" && (
                <Fade left>
                  <div className="col-xl-4 d-table mt-50 mb-50">
                    <img
                      src={localStorage.getItem("desktopBannerTwo")}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                      }}
                      alt="desktopBannerTwo"
                    />
                  </div>
                </Fade>
              )}
              {localStorage.getItem("desktopBannerThree") !== "null" && (
                <Fade left>
                  <div className="col-xl-4 d-table mt-50 mb-50">
                    <img
                      src={localStorage.getItem("desktopBannerThree")}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                      }}
                      alt="desktopBannerThree"
                    />
                  </div>
                </Fade>
              )}
              {localStorage.getItem("desktopBannerFour") !== "null" && (
                <Fade left>
                  <div className="col-xl-4 d-table mt-50 mb-50">
                    <img
                      src={localStorage.getItem("desktopBannerFour")}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                      }}
                      alt="desktopBanner"
                    />
                  </div>
                </Fade>
              )}
              {localStorage.getItem("desktopBannerFive") !== "null" && (
                <Fade left>
                  <div className="col-xl-4 d-table mt-50 mb-50">
                    <img
                      src={localStorage.getItem("desktopBannerFive")}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                      }}
                      alt="desktopBanner"
                    />
                  </div>
                </Fade>
              )}
              {localStorage.getItem("desktopBannerSix") !== "null" && (
                <Fade left>
                  <div className="col-xl-4 d-table mt-50 mb-50">
                    <img
                      src={localStorage.getItem("desktopBannerSix")}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                      }}
                      alt="desktopBanner"
                    />
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StoreAchievements;
