import { GET_RESTAURANTS_SLIDES_URL } from "../../../../configs/index";
import React, { Component } from "react";

import ContentLoader from "react-content-loader";
//import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";
// import LazyLoad from "react-lazyload";
// import { Redirect } from "react-router";

import { withRouter } from "react-router-dom";
import axios from "axios";
// import PromoSlider from "../PromoSlider";
// import Fade from "react-reveal/Fade";

import { connect } from "react-redux";
import {
  getDeliveryRestaurants,
  getSelfpickupRestaurants,
} from "../../../../services/restaurant/actions";
import StoreList from "../../../Shared/StoresList/StoreList";

class RestaurantList extends Component {
  state = {
    total: null,
    restaurants: [],
    loading: false,
    loading_more: true,
    selfpickup: false,
    userPreferredSelectionDelivery: true,
    userPreferredSelectionSelfPickup: false,
    no_restaurants: false,
    data: [],
    review_data: [],
    isHomeDelivery: true,
  };

  componentDidMount() {
    this.getAllRestaurantSliders();
    if (localStorage.getItem("enRAR") === "true" && "enRAR" === "disRAR") {
      this.getRatableOrdersDetails();
    }

    if (localStorage.getItem("userPreferredSelection") === "DELIVERY") {
      this.setState({
        userPreferredSelectionDelivery: true,
        isHomeDelivery: true,
      });
      this.filterDelivery();
    }
    if (
      localStorage.getItem("userPreferredSelection") === "SELFPICKUP" &&
      localStorage.getItem("enSPU") === "true"
    ) {
      this.setState({
        userPreferredSelectionSelfPickup: true,
        isHomeDelivery: false,
      });
      this.filterSelfPickup();
    } else {
      localStorage.setItem("userPreferredSelection", "DELIVERY");
      localStorage.setItem("userSelected", "DELIVERY");
      this.setState({
        userPreferredSelectionDelivery: true,
        isHomeDelivery: true,
      });
      this.__getDeliveryRestaurants();
    }
  }

  __getDeliveryRestaurants = () => {
    if (localStorage.getItem("userSetAddress")) {
      this.setState({
        loading: true,
      });
      const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
      this.props
        .getDeliveryRestaurants(userSetAddress.lat, userSetAddress.lng)
        .then((restaurants) => {
          if (restaurants && restaurants.payload.length) {
            this.setState({
              total: restaurants.payload.length,
              no_restaurants: false,
              loading: false,
              loading_more: false,
            });
          } else {
            this.setState({
              total: null,
              no_restaurants: true,
            });
          }
        });
    }
  };

  __getSelfPickupRestaurants = () => {
    if (localStorage.getItem("userSetAddress")) {
      this.setState({
        loading: true,
      });
      const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));

      this.props
        .getSelfpickupRestaurants(userSetAddress.lat, userSetAddress.lng)
        .then((restaurants) => {
          if (restaurants && restaurants.payload.length) {
            this.setState({
              total: restaurants.payload.length,
              no_restaurants: false,
              loading: false,
              loading_more: false,
            });
          } else {
            this.setState({
              total: null,
              loading: false,
              loading_more: false,
              no_restaurants: true,
            });
          }
        });
    }
  };

  filterDelivery = () => {
    this.setState(
      () => ({
        selfpickup: false,
        restaurants: [],
        userPreferredSelectionDelivery: true,
        userPreferredSelectionSelfPickup: false,
      }),
      () => {
        this.__getDeliveryRestaurants();
      }
    );
    localStorage.setItem("userPreferredSelection", "DELIVERY");
    if (localStorage.getItem("showPromoSlider") === "true") {
      // window.scrollTo({ top: 205, behavior: "smooth" });
    } else {
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  filterSelfPickup = () => {
    this.setState(
      () => ({
        selfpickup: true,
        restaurants: [],
        userPreferredSelectionSelfPickup: true,
        userPreferredSelectionDelivery: false,
      }),
      () => {
        this.__getSelfPickupRestaurants();
      }
    );
    localStorage.setItem("userPreferredSelection", "SELFPICKUP");
    if (localStorage.getItem("showPromoSlider") === "true") {
      // window.scrollTo({ top: 205, behavior: "smooth" });
    } else {
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  getAllRestaurantSliders = () => {
    axios.post(GET_RESTAURANTS_SLIDES_URL).then((response) => {
      if (response.data) {
        this.setState({
          data: response.data,
        });
      }
    });
  };

  changeRouteToRestaurantsCategories = (categories) => {
    if (categories.categories_ids) {
      const saveCategorySelectOptions = new Promise((resolve) => {
        localStorage.setItem(
          "categorySelectOptions",
          JSON.stringify(categories.categories_ids)
        );
        resolve("categorySelectOptions Saved");
      });
      saveCategorySelectOptions.then(() => {
        this.props.history.push("categories/stores");
      });
    }
  };

  render() {
    // const { user } = this.props;

    // if (!user.success) {
    //   return <Redirect to="/login" />;
    // }

    return (
      <React.Fragment>
        <div className="bg-white mb-100">
          {this.state.no_restaurants && (
            <div
              className={
                "bg-light " +
                (localStorage.getItem("enSPU") === "true" ? "sticky-top" : null)
              }
            >
              {localStorage.getItem("enSPU") === "true" ? (
                <React.Fragment>
                  <div className="px-15 py-3 d-flex justify-content-between align-items-center">
                    <h1 className="restaurant-count mb-0 mr-2">
                      {localStorage.getItem("noRestaurantMessage")}
                    </h1>

                    <div className="d-flex btn-group btn-preference-group">
                      <button
                        onClick={this.filterDelivery}
                        className={
                          "btn btn-preference " +
                          (this.state.userPreferredSelectionDelivery
                            ? "user-preferred"
                            : "")
                        }
                      >
                        {localStorage.getItem("deliveryTypeDelivery")}
                      </button>
                      <button
                        onClick={this.filterSelfPickup}
                        className={
                          "btn btn-preference " +
                          (this.state.userPreferredSelectionSelfPickup
                            ? "user-preferred"
                            : "")
                        }
                      >
                        {localStorage.getItem("deliveryTypeSelfPickup")}
                      </button>
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              ) : (
                <h1 className="restaurant-count mb-0 mr-2 px-15">
                  {localStorage.getItem("noRestaurantMessage")}
                </h1>
              )}
              <hr />
            </div>
          )}
          {this.state.total ? (
            <React.Fragment>
              <div
                className={
                  "bg-light " +
                  (localStorage.getItem("enSPU") === "true"
                    ? "sticky-top"
                    : null)
                }
              >
                {localStorage.getItem("enSPU") === "true" && (
                  <React.Fragment>
                    <div className="px-15 py-3 d-flex justify-content-between align-items-center">
                      <h1 className="restaurant-count mb-0 mr-2">
                        {!this.state.loading && this.state.total}{" "}
                        {localStorage.getItem("restaurantCountText")}
                      </h1>

                      <div className="d-flex btn-group btn-preference-group">
                        <button
                          onClick={this.filterDelivery}
                          className={
                            "btn btn-preference " +
                            (this.state.userPreferredSelectionDelivery
                              ? "user-preferred"
                              : "")
                          }
                        >
                          {localStorage.getItem("deliveryTypeDelivery")}
                        </button>
                        <button
                          onClick={this.filterSelfPickup}
                          className={
                            "btn btn-preference " +
                            (this.state.userPreferredSelectionSelfPickup
                              ? "user-preferred"
                              : "")
                          }
                        >
                          {localStorage.getItem("deliveryTypeSelfPickup")}
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          ) : null}

          {localStorage.getItem("restaurantCategorySliderPosition") === "0" && (
            <React.Fragment>
              {this.state.data.length > 0 && (
                <div className="slider-wrapper secondary-slider-wrapper my-0 pb-20">
                  {this.state.data.map((category) => (
                    <div
                      className="slider-wrapper__img-wrapper"
                      key={category.id}
                    >
                      <div
                        style={{ position: "relative" }}
                        onClick={() => {
                          this.changeRouteToRestaurantsCategories(category);
                        }}
                      >
                        <img
                          src={category.image}
                          alt={category.name}
                          className="slider-wrapper__img slider-cust-img"
                          style={{
                            height:
                              (12 / 5) *
                                parseInt(
                                  localStorage.getItem(
                                    "restaurantCategorySliderSize"
                                  )
                                ) +
                              "rem",
                            width:
                              (12 / 5) *
                                parseInt(
                                  localStorage.getItem(
                                    "restaurantCategorySliderSize"
                                  )
                                ) +
                              "rem",
                            borderRadius:
                              parseFloat(
                                localStorage.getItem(
                                  "restaurantCategorySliderStyle"
                                )
                              ) + "rem",
                          }}
                        />
                        {localStorage.getItem(
                          "showRestaurantCategorySliderLabel"
                        ) === "true" && (
                          <span className="category-slider-name">
                            {category.name}
                          </span>
                        )}
                        <Ink duration="500" hasTouch={true} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          )}
          {this.state.loading ? (
            <ContentLoader
              height={378}
              width={400}
              speed={1.2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
              <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
              <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

              <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
              <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
              <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

              <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
              <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
              <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
            </ContentLoader>
          ) : (
            <React.Fragment>
              {this.props.restaurants.length === 0 ? (
                <ContentLoader
                  height={378}
                  width={400}
                  speed={1.2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                  <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                  <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                  <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                  <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                  <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                  <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                  <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                  <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
                </ContentLoader>
              ) : (
                <div>
                  <StoreList
                    storeList={this.props.restaurants}
                    changeRouteToRestaurantsCategories={
                      this.changeRouteToRestaurantsCategories
                    }
                    slides={this.props.slides}
                    loading={this.state.loading}
                    incomingData={this.state.data}
                    review_data={this.state.review_data}
                  />
                </div>
              )}
            </React.Fragment>
          )}

          {this.state.loading_more ? (
            <div className="">
              <ContentLoader
                height={120}
                width={400}
                speed={1.2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
              </ContentLoader>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

// export default withRouter(RestaurantList);

const mapStateToProps = (state) => ({
  restaurants: state.restaurant.restaurants,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getDeliveryRestaurants,
      getSelfpickupRestaurants,
    }
  )(RestaurantList)
);
