import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import Ink from "react-ink";

class PromoSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.startSlideShow();
  }

  componentWillUnmount() {
    this.stopSlideshow();
  }

  startSlideShow = () => {
    const { slides } = this.props;
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        currentSlide: (prevState.currentSlide + 1) % slides.length,
      }));
    }, 5000);
  };

  stopSlideshow = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  goToSlide = (index) => {
    this.setState({ currentSlide: index });
  };

  render() {
    const { slides, size, secondarySlider } = this.props;

    return (
      <React.Fragment>
        <div
          style={{ background: "#fffefe" }}
          className={`
            ${
              secondarySlider
                ? "slider-wrapper secondary-slider-wrapper my-20"
                : "auto-slider-wrapper py-20  px-15 d-flex align-items-center justify-content-center"
            } ${
            this.props.isCartPage
              ? "margin-top-0 slider-border-radius d-flex align-items-center justify-content-center flex-column"
              : "my-0"
          }`}
        >
          {slides.length === 0 ? (
            <ContentLoader
              height={170}
              width={400}
              speed={1.2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
              <rect x="228" y="0" rx="4" ry="4" width="168" height="168" />
            </ContentLoader>
          ) : (
            <React.Fragment>
              {this.props.isCartPage ? (
                <React.Fragment>
                  <a
                    onPointerEnter={this.stopSlideshow}
                    onPointerLeave={this.startSlideShow}
                    href={slides[this.state.currentSlide].url}
                    className="slider-wrapper__img-wrapper d-flex align-items-center justify-content-center"
                    key={slides[this.state.currentSlide].data.id}
                    style={{ position: "relative", width: "100%" }}
                  >
                    <img
                      src={slides[this.state.currentSlide].data.image}
                      alt={slides[this.state.currentSlide].data.name}
                      className={`slider-wrapper__img slider-cust-img ${!secondarySlider &&
                        "slider-wrapper__img-shadow"} custom-promo-img`}
                      style={{ height: (12 / 5) * size + "rem", width: "100%" }}
                    />
                  </a>
                  <div className="dots">
                    {slides.map((_, index) => (
                      <span
                        key={index}
                        className={`dot ${
                          index === this.state.currentSlide ? "active" : ""
                        }`}
                        onClick={() => this.goToSlide(index)}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {slides.map((slide) =>
                    slide.data.model === "3" ? (
                      <a
                        href={slide.url}
                        className="slider-wrapper__img-wrapper"
                        key={slide.data.id}
                        style={{ position: "relative" }}
                      >
                        {/* if customURL then use anchor tag */}
                        <LazyLoad>
                          <img
                            src={slide.data.image}
                            alt={slide.data.name}
                            className={`slider-wrapper__img slider-cust-img ${!secondarySlider &&
                              "slider-wrapper__img-shadow"} custom-promo-img`}
                            style={{
                              height: (12 / 5) * size + "rem",
                              width: (12 / 5) * size + "rem",
                            }}
                          />
                        </LazyLoad>
                        <Ink duration="500" hasTouch={true} />
                      </a>
                    ) : (
                      <NavLink
                        to={"../" + slide.url}
                        className="slider-wrapper__img-wrapper anchor-link"
                        key={slide.data.id}
                        style={{ position: "relative" }}
                      >
                        <LazyLoad>
                          <img
                            src={slide.data.image}
                            alt={slide.data.name}
                            className={`slider-wrapper__img slider-cust-img ${!secondarySlider &&
                              "slider-wrapper__img-shadow"} custom-promo-img`}
                            style={{
                              height: (12 / 5) * size + "rem",
                              width: (12 / 5) * size + "rem",
                            }}
                          />
                        </LazyLoad>
                        <Ink duration="500" hasTouch={true} />
                      </NavLink>
                    )
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PromoSlider;
