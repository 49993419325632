import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist-indexeddb-storage';

import rootReducer from "./reducers";


const persistConfig = {
	key: 'root',
	storage: storage('sports and style')
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default (initialState) => {
	const middleware = [thunk];
	const store = createStore(
		persistedReducer,
		initialState,
		compose(
			applyMiddleware(...middleware)
			//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	);

	store.subscribe(() => {
		const state = store.getState();
		//save the config to localstorage
		for (const setting in state.settings.settings) {
			if (state.settings.settings.hasOwnProperty(setting)) {
				const element = state.settings.settings[setting];
				localStorage.setItem(element.key, element.value);
			}
		}

		for (const translation in state.languages.language) {
			if (state.languages.language.hasOwnProperty(translation)) {
				const trans = state.languages.language[translation];
				localStorage.setItem(translation, trans);
			}
		}

		// const persist = {
		// 	cart: state.cart,
		// 	total: state.total,
		// 	items: state.items,
		// 	pages: state.pages,
		// 	user: state.user,
		// 	addresses: state.addresses,
		// 	delivery_user: state.delivery_user,
		// 	languages: state.languages.languages,
		// 	settings: state.settings,
		// };
		// localForage.setItem("state", JSON.stringify(persist));
	});
	const persistor = persistStore(store);
	return { store, persistor };
};