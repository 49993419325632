import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT, GET_SLOTS, DELIVERY_DATE, DELIVERY_TIME_SLOT } from "./actionTypes";

const initialState = {
    products: [],
    timeSlots: [],
    futureDateData: null,
    deliveryDate: null,
    deliveryTimeSlot: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_CART:
            return {
                ...state,
                products: action.payload
            };
        case ADD_PRODUCT:
            return {
                ...state,
                productToAdd: Object.assign({}, action.payload)
            };
        case REMOVE_PRODUCT:
            return {
                ...state,
                productToRemove: Object.assign({}, action.payload)
            };
        case GET_SLOTS:
            return {
                ...state,
                //timeSlots: action.payload
                timeSlots: action.payload.timeSlots, 
                futureDateData: action.payload.futureDateData,
            };
        case DELIVERY_DATE:
            return {
                ...state,
                deliveryDate: action.payload
            };
        case DELIVERY_TIME_SLOT:
            return {
                ...state,
                deliveryTimeSlot: action.payload
            };
        default:
            return state;
    }
}
