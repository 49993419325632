import React, { Component } from "react";
import Ink from "react-ink";
import { withRouter } from "react-router-dom";

class WebShare extends Component {
  state = {
    shareButton: false,
  };
  componentDidMount() {
    if (navigator.share) {
      this.setState({ shareButton: true });
    }
  }

  shareLink = (link, title) => {
    console.log(link);
    if (navigator.share) {
      navigator
        .share({
          url: "https://elevateyoursport.com/public/product/" + link,
          text: title,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.shareButton && (
          <button
            type="button"
            className="btn search-navs-btns nav-home-btn"
            style={{ position: "relative" }}
            onClick={() => this.shareLink(this.props.link, this.props.title)}
          >
            <i className="si si-share-alt" />
            <Ink duration="500" />
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(WebShare);
