import React from "react";
import { useState } from "react";
import Ink from "react-ink";
import LazyLoad from "react-lazyload";
import DelayLink from "../../helpers/delayLink";
import PromoSlider from "../../Mobile/Home/PromoSlider";
import classes from "./StoreList.module.css";

const StoreList = ({
  storeList,
  changeRouteToRestaurantsCategories,
  slides,
  loading,
  incomingData,
  review_data,
}) => {
  const sortByDistance = (storeList) => {
    const store = storeList.sort((a, b) => a.distance - b.distance);
    // setSelectedOption(2);
    return store;
  };
  const [sortedStore, setSortedStores] = useState(sortByDistance(storeList));
  const [selectedOption, setSelectedOption] = useState(2);

  const sortStoreBy = (e) => {
    if (e.target.value === "1") {
      const store = sortedStore.sort(
        (a, b) =>
          parseFloat(b.rating.toString()) - parseFloat(a.rating.toString())
      );
      setSortedStores(store);
      setSelectedOption(e.target.value);
    } else if (e.target.value === "2") {
      const store = sortedStore.sort((a, b) => a.distance - b.distance);
      setSortedStores(store);
      setSelectedOption(e.target.value);
    } else {
      console.log("Select the filter");
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center px-6">
        <select
          onChange={sortStoreBy}
          defaultValue="2"
          className={`${classes["sort-by"]}`}
        >
          {/* <option value="0" disabled>
            Select Filter
          </option> */}
          <option value={1}>Rating</option>
          <option value={2}>Distance</option>
        </select>
      </div>
      {sortedStore.map((restaurant, index) => (
        <React.Fragment key={restaurant.id}>
          <LazyLoad>
            <div className="col-xs-12 col-sm-12 restaurant-block">
              <DelayLink
                to={"../stores/" + restaurant.slug}
                delay={200}
                className="d-flex text-center anchor-link"
                clickAction={() => {
                  localStorage.getItem("userPreferredSelection") ===
                    "DELIVERY" &&
                    restaurant.delivery_type === 1 &&
                    localStorage.setItem("userSelected", "DELIVERY");
                  localStorage.getItem("userPreferredSelection") ===
                    "SELFPICKUP" &&
                    restaurant.delivery_type === 2 &&
                    localStorage.setItem("userSelected", "SELFPICKUP");
                  localStorage.getItem("userPreferredSelection") ===
                    "DELIVERY" &&
                    restaurant.delivery_type === 3 &&
                    localStorage.setItem("userSelected", "DELIVERY");
                  localStorage.getItem("userPreferredSelection") ===
                    "SELFPICKUP" &&
                    restaurant.delivery_type === 3 &&
                    localStorage.setItem("userSelected", "SELFPICKUP");
                }}
              >
                <div className="block-content-full px-6">
                  {/* {restaurant.is_featured ? (
                              <div className="ribbon-box">
                                {localStorage.getItem("restaurantFeaturedText")}
                              </div>
                            ) : null} */}

                  <div className="shop-image-container">
                    <img
                      //src="/assets/img/various/shop-placeholder.jpeg"
                      src={restaurant.image}
                      alt={restaurant.name}
                      className={`restaurant-image-w100 shop-image ${!restaurant.is_active &&
                        "restaurant-not-active"}`}
                    />
                  </div>
                  {/* </Fade> */}
                </div>

                <div className="block-content-full restaurant-info">
                  <div className="font-w600 text-dark restaurent-name">
                    {restaurant.name}
                  </div>
                  <div className="text-muted truncate-text text-muted restaurant-speciality">
                    {restaurant.description}
                  </div>
                  {!restaurant.is_active && (
                    <span className="restaurant-not-active-msg restaurant-rating">
                      {localStorage.getItem("restaurantNotActiveMsg")}
                    </span>
                  )}
                  <div className="d-flex align-items-center">
                    {restaurant.rating ? (
                      <span
                        className="restaurant-rating"
                        style={{
                          marginRight: "10px",
                          width: "35px",
                        }}
                      >
                        <i
                          className="si si-star"
                          style={{ color: "#ffa500" }}
                        />
                        <span style={{ padding: "5px" }}>
                          {restaurant.rating}
                        </span>
                      </span>
                    ) : null}
                    {/* <Fade duration={500}> */}
                    <span
                      className={`${
                        restaurant.is_featured && restaurant.is_active
                          ? "ribbon ribbon-bookmark ribbon-warning px-6"
                          : "px-6"
                      } `}
                    >
                      {restaurant.is_featured ? (
                        <div className="ribbon-box">
                          {localStorage.getItem("restaurantFeaturedText")}
                        </div>
                      ) : null}
                    </span>
                    {/* </Fade> */}
                  </div>
                  {/* <hr className="my-10" /> */}
                  {/* <div className="text-center restaurant-meta mt-5 d-flex align-items-center justify-content-between text-muted">
                              {localStorage.getItem("enableBtoB") == "true" &&
                              localStorage.getItem("showMappedOnlyStore") ==
                                "true" ? (
                                <React.Fragment>
                                  <div className="col-2 p-0 text-left">
                                    <i
                                      className={`fa fa-star pr-1 ${!restaurant.is_active &&
                                        "restaurant-not-active"}`}
                                      style={{
                                        color: localStorage.getItem(
                                          "storeColor"
                                        ),
                                      }}
                                    />{" "}
                                    {restaurant.avgRating === "0"
                                      ? restaurant.rating
                                      : restaurant.avgRating}
                                  </div>
                                  <div className="col-4 p-0 text-center">
                                    {this.state.selfpickup ? (
                                      <span>
                                        <i className="si si-pointer pr-1" />
                                        {restaurant.distance &&
                                          restaurant.distance.toFixed(1)}{" "}
                                        Km
                                      </span>
                                    ) : (
                                      <span
                                        style={
                                          restaurant.delivery_time == "0"
                                            ? { display: "none" }
                                            : { display: "block" }
                                        }
                                      >
                                        <i className="si si-clock pr-1" />{" "}
                                        {restaurant.delivery_time}{" "}
                                        {localStorage.getItem(
                                          "homePageMinsText"
                                        )}
                                      </span>
                                    )}
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {restaurant.delivery_days ? (
                                    <div className="col-6 p-0 text-left">
                                      <span>
                                        <i className="si si-clock pr-1" />{" "}
                                        {restaurant.delivery_days}
                                      </span>
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              )}
                              <div className="col-6 p-0 text-center">
																<i className="si si-wallet" />{" "}
																{localStorage.getItem("currencySymbolAlign") ===
																	"left" && (
																		<React.Fragment>
																			{localStorage.getItem("currencyFormat")}
																			{restaurant.price_range}{" "}
																		</React.Fragment>
																	)}
																{localStorage.getItem("currencySymbolAlign") ===
																	"right" && (
																		<React.Fragment>
																			{restaurant.price_range}
																			{localStorage.getItem("currencyFormat")}{" "}
																		</React.Fragment>
																	)}
																{localStorage.getItem("homePageForTwoText")}
															</div>
                            </div> */}
                </div>
                <Ink duration="500" hasTouch={false} />
              </DelayLink>
            </div>
          </LazyLoad>
          {localStorage.getItem("showPromoSlider") === "true" && (
            <React.Fragment>
              {slides && slides.length > 0 && (
                <React.Fragment>
                  {index === slides[0]["promo_slider"]["position_id"] - 1 && (
                    <PromoSlider
                      slides={slides}
                      size={slides[0]["promo_slider"]["size"]}
                      secondarySlider={true}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {localStorage.getItem("enRestaurantCategorySlider") === "true" && (
            <React.Fragment>
              {index ===
                parseInt(
                  localStorage.getItem("restaurantCategorySliderPosition")
                ) -
                  1 && (
                <React.Fragment>
                  {incomingData.length > 0 && (
                    <div className="slider-wrapper secondary-slider-wrapper my-20">
                      {incomingData.map((category) => (
                        <div
                          className="slider-wrapper__img-wrapper"
                          key={category.id}
                        >
                          <div
                            style={{ position: "relative" }}
                            onClick={() => {
                              changeRouteToRestaurantsCategories(category);
                            }}
                          >
                            <img
                              src={category.image}
                              alt={category.name}
                              className="slider-wrapper__img slider-cust-img"
                              style={{
                                height:
                                  (12 / 5) *
                                    parseInt(
                                      localStorage.getItem(
                                        "restaurantCategorySliderSize"
                                      )
                                    ) +
                                  "rem",
                                width:
                                  (12 / 5) *
                                    parseInt(
                                      localStorage.getItem(
                                        "restaurantCategorySliderSize"
                                      )
                                    ) +
                                  "rem",
                                borderRadius:
                                  parseFloat(
                                    localStorage.getItem(
                                      "restaurantCategorySliderStyle"
                                    )
                                  ) + "rem",
                              }}
                            />
                            {localStorage.getItem(
                              "showRestaurantCategorySliderLabel"
                            ) === "true" && (
                              <span className="category-slider-name">
                                {category.name}
                              </span>
                            )}
                            <Ink duration="500" hasTouch={true} />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {review_data.ratable &&
            localStorage.getItem("enRAR") === "true" &&
            localStorage.getItem("rarModEnHomeBanner") === "true" &&
            "enRAR" === "disRAR" && (
              <React.Fragment>
                {!loading &&
                  index ===
                    parseInt(localStorage.getItem("rarModHomeBannerPosition")) -
                      1 && (
                    <div
                      className="col-xs-12 col-sm-12 rating-block px-15 py-10"
                      style={{
                        backgroundColor: localStorage.getItem(
                          "rarModHomeBannerBgColor"
                        ),
                      }}
                    >
                      <DelayLink
                        to={{
                          pathname: "../rate-and-review/" + review_data.data.id,
                        }}
                        delay={250}
                        className="anchor-link"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <strong
                            style={{
                              color: localStorage.getItem(
                                "rarModHomeBannerTextColor"
                              ),
                            }}
                          >
                            {localStorage.getItem("rarModHomeBannerText")}
                            {localStorage.getItem(
                              "rarModShowBannerRestaurantName"
                            ) === "true" && review_data.data.restaurant.name}
                          </strong>
                          <span
                            className="btn btn-default"
                            style={{
                              minWidth: "100px",
                              textAlign: "center",
                            }}
                          >
                            {this.animateStarIcon()}
                          </span>
                        </div>
                        <Ink duration="500" hasTouch={false} />
                      </DelayLink>
                    </div>
                  )}
              </React.Fragment>
            )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StoreList;
