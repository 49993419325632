import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  applyCoupon,
  getCouponList,
} from "../../../../../services/coupon/actions";
import BackWithSearch from "../../../Elements/BackWithSearch";
import classes from "./CouponList.module.css";
import Ink from "react-ink";
import { formatPrice } from "../../../../helpers/formatPrice";

const CouponList = ({ history }) => {
  const dispatch = useDispatch();
  const [couponList, setCouponList] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [enteredCoupon, setEnteredCoupon] = useState();
  const storeId = useSelector((state) => state.items.restaurant_info.id);
  const token = useSelector((state) => state.user.user.data.auth_token);
  const subtotal = useSelector((state) => state.total.data.totalPrice);

  useEffect(() => {
    const fetchCouponList = async () => {
      try {
        const result = await getCouponList(storeId, token);
        setCouponList(result.coupons);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCouponList();
  }, []);

  const handleInput = (event) => {
    setEnteredCoupon(event.target.value);
  };

  const getExpireDate = (incomingDate) => {
    const splitDate = incomingDate.split(" ");
    return splitDate[0];
  };

  const setCoupon = (coupon) => {
    if (Number(coupon.min_subtotal) > subtotal) {
      return;
    }
    setSelectedCoupon(coupon);
    dispatch(applyCoupon(token, coupon.code, storeId, subtotal));

    history.goBack();
  };

  const setAppliedCoupn = () => {
    if (enteredCoupon && enteredCoupon.length) {
      dispatch(applyCoupon(token, enteredCoupon, storeId, subtotal));
      history.push("/cart");
    }
  };

  const showMoreDetails = (index, couponCode) => {
    var showMore = document.getElementById(index);
    var showLess = document.getElementById(index + couponCode);
    if (showLess.style.display === "none" || showLess.style.display === "") {
      showMore.style.display = "none";
      showLess.style.display = "block";
    } else {
      showMore.style.display = "block";
      showLess.style.display = "none";
    }
  };

  const showApplyBtn = (min_subtotal) => {
    if (Number(min_subtotal) > subtotal) {
      return false;
    } else {
      return true;
    }
  };

  const getDifferenceAmount = (min_subtotal) => {
    return Number(min_subtotal) - subtotal;
  };

  return (
    <div>
      <BackWithSearch
        boxshadow={true}
        has_title={true}
        title="Coupons"
        disable_search={true}
        homeButton={false}
        restaurant={{}}
      />
      <div
        className={`pt-60 d-flex justify-content-center flex-column align-items-center ${
          classes["coupon-list"]
        }`}
      >
        <div className="input-group align-items-center">
          <input
            type="text"
            className="form-control apply-coupon-input"
            placeholder={localStorage.getItem("cartCouponText")}
            style={{
              color: localStorage.getItem("storeColor"),
              height: "2.8rem",
              borderRadius: "5px",
            }}
            onChange={handleInput}
            spellCheck="false"
          />
          <div className="input-group-append">
            <button
              className="btn apply-coupon-btn"
              onClick={(event) => {
                event.preventDefault();
                setAppliedCoupn();
              }}
            >
              <span
                style={{
                  backgroundColor: localStorage.getItem("cartColorBg"),
                  color: localStorage.getItem("cartColorText"),
                }}
              >
                {localStorage.getItem("applyCouponButtonText")}
              </span>

              <Ink duration="500" />
            </button>
          </div>
        </div>

        {couponList.length ? (
          couponList.map((coupon, index) => (
            <div
              key={index}
              className={`d-flex justify-content-center flex-column ${
                classes["coupon-card"]
              }`}
            >
              <div
                className={`d-flex align-items-center justify-content-center ${
                  classes["coupon-card-header"]
                }`}
              >
                <div className={`${classes["coupon-header-img-container"]}`}>
                  <img
                    className={`${classes["coupon-header-img"]}`}
                    src="/assets/img/logos/coupon-icon.png"
                    alt="coupon-icon.png"
                  />
                </div>
                <div
                  className={`d-flex justify-content-start flex-column ${
                    classes["coupon-header-name-container"]
                  }`}
                >
                  <span className={`${classes["coupon-name"]}`}>
                    {coupon.name}
                  </span>
                  <span className={`${classes["coupon-code"]}`}>
                    Coupon code {coupon.code}
                  </span>
                </div>

                <div
                  className={`d-flex justify-content-center align-items-center ${
                    classes["coupon-header-btn-container"]
                  }`}
                >
                  {showApplyBtn(coupon.min_subtotal) ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setCoupon(coupon);
                      }}
                      className={` ${classes["coupon-apply-btn"]}`}
                    >
                      {localStorage.getItem("applyCouponButtonText")}
                    </button>
                  ) : null}
                </div>
              </div>
              <div
                className={`${classes["coupon-card-footer"]}`}
                id={index}
                onClick={(e) => {
                  e.preventDefault();
                  showMoreDetails(index, coupon.code);
                }}
              >
                <span className={`${classes["show-more-less"]}`}>+ More</span>
              </div>

              <div
                id={index + coupon.code}
                className={`${classes["coupon-card-footer"]}`}
                style={{ display: "none" }}
              >
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    showMoreDetails(index, coupon.code);
                  }}
                  className={`${classes["show-more-less"]}`}
                >
                  - Less
                </span>
                <ul>
                  {coupon.subtotal_message && (
                    <li>{coupon.subtotal_message}</li>
                  )}
                  {coupon.expiry_date && (
                    <li>
                      Coupon will expire on {getExpireDate(coupon.expiry_date)}
                    </li>
                  )}
                  {coupon.description && <li>{coupon.description}</li>}
                  {coupon.max_discount && (
                    <li>
                      Maximum
                      {localStorage.getItem("currencySymbolAlign") === "left" &&
                        localStorage.getItem("currencyFormat")}
                      {formatPrice(Number(coupon.max_discount))}
                      {localStorage.getItem("currencySymbolAlign") ===
                        "right" && localStorage.getItem("currencyFormat")}{" "}
                      will be applied.
                    </li>
                  )}
                </ul>
              </div>
              {!showApplyBtn(coupon.min_subtotal) ? (
                <span className={`${classes["offer-difference"]}`}>
                  Add{" "}
                  {localStorage.getItem("currencySymbolAlign") === "left" &&
                    localStorage.getItem("currencyFormat")}
                  {formatPrice(getDifferenceAmount(coupon.min_subtotal))}
                  {localStorage.getItem("currencySymbolAlign") === "right" &&
                    localStorage.getItem("currencyFormat")}{" "}
                  more to avail this offer
                </span>
              ) : null}
            </div>
          ))
        ) : (
          <div>
            <p>Coupons not available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CouponList);
