import {
  CLEAR_SEARCH,
  SEARCH_ITEMS,
  SEARCH_RESTAURANTS,
  CLEAR_ITEM_SEARCH,
} from "./actionTypes";

const initialState = {
  restaurants: [],
  searchItems: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SEARCH_RESTAURANTS:
      return { ...state, restaurants: action.payload };

    case CLEAR_SEARCH:
      return { ...state, restaurants: action.payload };

    case SEARCH_ITEMS:
      return { ...state, searchItems: action.payload };

    case CLEAR_ITEM_SEARCH:
      return { ...state, searchItems: action.payload };
    default:
      return state;
  }
}
