import React, { Component } from "react";

class Loading extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="height-100 overlay-loading ongoing-payment-spin">
          <div className="spin-load" />
        </div>
      </React.Fragment>
    );
  }
}

export default Loading;
